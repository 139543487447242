<template>
  <div id="Setup">
    <v-container>
      <v-row class="text-center pt-5">
        <v-col cols="12">Setup</v-col>
        <v-col cols="12">
          <v-card>
            <v-card-text class="pa-1">
              <qrcode v-if="qr" :value="qr" tag="canvas" :options="{width:320}"></qrcode>
            </v-card-text>
            <v-card-subtitle class="pa-1 pt-0 pb-2">Scan QR Code</v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed color="blue" @click="setupQR">SETUP</v-btn>
              <v-btn depressed color="blue" @click="generateQR">GENERATE QR</v-btn>
              <v-btn depressed color="green" @click="restoreSession">RESTORE SESSION</v-btn>
              <v-btn depressed color="red" @click="destroySession">DESTROY SESSION</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import { EventBus } from '@/plugins/EventBus'

export default {
  name: 'Setup',
  props: { url: { type: [String], default: null } },
  data () {return { qr: null, instance: null, instanceStatus: {} }},
  mounted () {
    this.instance = this.$store.getters.instance
    EventBus.$on('instanceUpdate', instance => {
      this.instance = instance
      this.$forceUpdate()
    })
    this.instanceStatus = this.$store.getters.instanceStatus
    EventBus.$on('instanceStatusUpdate', instance => {
      this.instanceStatus = instance
      this.$forceUpdate()
    })
  },
  methods: {
    setupQR () {
      axios.post(`${this.url}setup`).then(res => {console.log(res)}).catch(err => {console.log(err)})
    }, generateQR () {
      axios.post(`${this.url}setup/qr`).then(res => {
        if (res.data.flag)
          this.qr = res.data.qr
      }).catch(err => {console.log(err)})
    },
    restoreSession () {
      axios.post(`${this.url}setup/restoreSession`).catch(err => {console.log(err)})
    },
    destroySession () {
      axios.post(`${this.url}setup/destroySession`).catch(err => {console.log(err)})
    },
  },
}
</script>

<style scoped>

</style>
